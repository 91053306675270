<script setup lang="ts">
import type { Placement } from '@floating-ui/dom'
import { datePresets } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

const workspace = useWorkspace()

defineProps<{
  placement?: Placement
}>()

const batchItem = computed(() => ({
  label:
    (workspace.activeConnector?.batch_label as string) ||
    datePresets.sinceInception,
  itemValue: 'custom_time_frame',
}))

const items = computed(() => [batchItem.value])
</script>

<template>
  <UiDropdown
    :items="items"
    :model-value="batchItem"
    :placement="placement || 'bottom-start'"
  />
</template>
