<script setup lang="ts">
const { today } = useClock()
const { timezoneMeta, timezone } = useWorkspaceTimezone()

const time = computed(() =>
  timezoneFormatter(
    timezone.value,
    intlDateFormats.timeWithTimeZoneName,
  ).format(today.value),
)
</script>

<template>
  <UiTooltip>
    <template #activator>
      <time class="subtitle-2 text-neutral-900 truncate" :datetime="time">
        {{ time }}
      </time>
    </template>

    <div class="body-2 max-w-prose">
      <p class="mb-1">
        Your workspace’s timezone is
        <span class="font-semibold">{{ timezoneMeta?.name }}</span>
      </p>

      <p class="opacity-80">
        Contact your workspace admin if you believe there is an issue.
      </p>
    </div>
  </UiTooltip>
</template>
